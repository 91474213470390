$darkerBlue: #003f80;
$darkBlue: #005dbf;
$clearBlue: #01a0f6;
$tenueBlue: #d5e3f2;
$whiteBlue: #ccdce4;
$whiteGray: #a9abae;
$darkerGray: #58595b;
$darkGray: #58595b;
$parragraphColor: #606062;
$bgFormBlue: #eff4fb;

$opaqueBlue: #f2f7fc;
$green: #00a551;
$red: #ec1c24;
$orange: #ff8b00;
$yellow: #f2c94c;
$bgFormBlue: #eff4fb;

$diabledBg: #f4f4f4;
$clearGray: #cecece;
$green: #00a551;
$red: #ec1c24;
$orange: #ff8b00;
$Clearorange: rgba(255, 139, 0, 0.15);
$yellow: #f2c94c;

$tableWithoutSelect: #005dbf 0.6;
$tableSelect: #005dbf 0.2;
$tableEven: #f5f7fa;
$tableHover: #d2dde8;
$tableAddition: rgba(255, 139, 0, 0.3);
$tableAdditionHover: rgba(255, 139, 0, 0.5);
$tableTransladar: rgba(255, 139, 0, 0.15);
$tableTransladarHover: rgba(255, 139, 0, 0.2);
$tableText: #697387;
$font-Secular: "PT Sans Caption", sans-serif;

$paginationSelected: #f5f7fa;
$paginationHover: #d2dde8;

$bg: #f3f8fa;
$white: #fff;
$black: #282936;

$greenEnabledOld: #0edd4e;
$greenEnabled: #00a551;
$redDisabled: #ec1c24;
$yellowAux: #f2c94c;

$backgroundDarkBlue: rgba(0, 93, 191, 0.1);
$darkBlueHover: #003f80;
$bgInputDisabled: #f4f4f4;

@import "../../styleConstants.scss";
$circleTimeLine: url("../../assets/img/icons/circleTimeline.svg");
.genericTable {
  padding: 0 1.5rem;
  top: 3rem;
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  border-radius: 15px;
  margin-top: 1rem;
  text-align: center;
  border-spacing: 0;
}
.greenCheck {
  background-image: url("../../assets/img/icons/CheckMark.svg");
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.1rem;
  width: "-webkit-fill-available";
  margin: auto;
}

.alertTriangle {
  //   background-image: url("../../assets/img/icons/Alert.svg");
  background-repeat: no-repeat;
  width: "-webkit-fill-available";
  width: 1.5rem;
  height: 1.1rem;
  margin: auto;
}
.genericTable > tbody > tr > td {
  border: none;
  padding: 0.4rem 0;
  text-transform: capitalize;
}

.genericTable > tbody > tr {
  background-color: $white;
}

.genericTable > tbody > tr:nth-of-type(even) {
  background-color: $tableEven;
}

.genericTable > tbody > tr:hover {
  background-color: $tableHover;
  transition: 0.3s all ease-in-out;
}

.genericTable > tbody > tr > td {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 500;
  vertical-align: middle;
}

.genericTable > tbody > tr > td:first-child {
  padding-left: 2.5rem;
}

.theadLight {
  color: $white;
  background-color: $darkBlue;
}

.theadLight > tr > th {
  padding: 0.5rem 0;
}

.groupElems {
  // max-width: 7rem;
  display: flex;
  margin-right: 0;
  margin-left: auto;
  justify-content: space-between;
  vertical-align: middle;
}
.groupElemsWithoutMarginL {
  max-width: 5rem;
  display: flex;
  margin-right: 0;
  justify-content: space-between;
  vertical-align: middle;
}

.iconWrap {
  display: flex;
  margin-right: 0;
  margin-left: auto;
  justify-content: space-between;
  vertical-align: bottom;
  border-radius: 6px;
  padding: 5px;
  align-items: end;
  cursor: pointer;
}
.iconWrap:hover {
  background-color: rgba($darkBlue, 0.1);
}
.iconWrap:hover > .iconLabel {
  color: $darkBlue;
}
.iconWrap > img {
  width: 1rem;
  margin: 0 0.3rem;
}
.iconWrap:hover > img {
  filter: brightness(0) saturate(100%) invert(17%) sepia(68%) saturate(4437%)
    hue-rotate(201deg) brightness(98%) contrast(100%);
}
.iconLabel {
  font-family: "PT Sans Caption";
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #58595b;
}
.iconSvg {
  width: 1.7rem;
  margin: 0 0.5rem;
}
.iconSvgSmall {
  width: 1rem;
  margin: 0 0.3rem;
}
.iconSvgMedium {
  width: 3.5rem;
  margin: 0;
}
.iconSvgMargin {
  margin: 0 0.5rem;
}
.iconSvgMargin2 {
  margin-bottom: 2px;
  margin-left: 5px;
}
.iconSvg:hover,
.iconSvgSmall:hover,
.iconSvgMedium:hover {
  cursor: pointer;
  opacity: 0.8;
}

.iconsGear {
  width: 2rem;
  margin: 0 0.5rem;
}
.circleGreen {
  background: $greenEnabled;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.circleRed {
  background: red;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
  //margin: auto .5rem;
}

.circleYellow {
  background: #f2c94c;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.circleBlack {
  background: #58595b;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.cursorFlecha {
  cursor: default;
}
.cursorPointer:hover {
  cursor: pointer;
}
.circleGreenAuto {
  background: $greenEnabled;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: auto;
}
.circleRedAuto {
  background: red;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: auto;
}
.circleYellowAuto {
  background: #f2c94c;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: auto;
}
.circleBlackAuto {
  background: #58595b;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: auto;
}

.mainRow {
  display: inline-flex;
  width: 100%;
  vertical-align: middle;
  justify-content: space-between;
  //padding: 0 .5rem;
}

.container {
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.tableArea {
  //margin:0 5rem;
  margin: 0 76px;
}
.searchUsersAlt {
  border: 1.5px solid $darkBlue;
  border-radius: 5px;
  padding-left: 0.5rem;
}

.inputText {
  border: 1.5px solid $darkBlue;
  flex: 1;
  border-radius: 5px;
  padding-left: 0.5rem;
}

.crudModalRow {
  display: flex;
}
.crudModalRow > div {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
.crudModalRow > div:last-child {
  margin-right: 0;
}
.crudModalItemBase {
  display: flex;
  flex-direction: column;
}

.crudModalItemTiny {
  flex: 3;
  margin-right: 0.4rem;
  display: flex;
  flex-direction: column;
}
.crudModalItemSmall {
  flex: 7;
  display: flex;
  flex-direction: column;
}
.crudModalItemBig {
  flex: 10;
  margin: 0 0.2rem;
  display: flex;
  flex-direction: row;
}
.crudModalrightMargin {
  margin-right: 0.4rem;
}

.crudModalLabel {
  font-size: 0.75rem;
  margin: 0.4rem 0.3rem 0 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $darkBlue;
}

.crudModalLabelClear {
  font-size: 0.75rem;
  margin: 0.4rem 0.3rem 0 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: $clearBlue;
}

.crudModalInputDate {
  font-size: 1vw;
  // flex: 1 1 auto;
}

.crudModalInputDate::-webkit-calendar-picker-indicator {
  filter: invert(15%) sepia(92%) saturate(5062%) hue-rotate(201deg)
    brightness(93%) contrast(101%);
}

.crudModalZonaCuenta {
  display: flex;
  flex-direction: column;
  background-color: rgba(88, 89, 91, 0.05);
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
}

.crudModalZonaPerfiles {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  padding: 0.5rem 2rem;
}

.crudModalPerfilesWrap {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
}

.myCheckbox {
  margin: 0 1rem;
}

.myCheckboxLabel {
  margin-left: 0.5rem;
  text-transform: capitalize;
  vertical-align: middle;
}

.myCheckboxInput {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  vertical-align: middle;
}
.flex3 {
  flex: 3;
}
.flex1 {
  flex: 1;
  display: flex;
  padding: 0 0.3rem;
}

.textCenter,
.genericTable > tbody > tr > .textCenter {
  text-align: center;
}

.textleft {
  text-align: left;
}

.blueSearch {
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 6px;
  width: 100%;
  border: none;
  padding: 0 0.5rem;
  height: 27px;
  color: $darkBlue;
  &::placeholder {
    color: rgba($darkBlue, 0.5);
  }
}

.btnPrimary {
  background-color: $clearBlue;
  border: 1.5px solid $clearBlue;
  font-family: "PT Sans";
  color: #fff;
  min-width: 110px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
}

.btnPrimary:hover {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  color: #fff;
}

.btnPrimaryAlt:disabled,
.btnPrimary:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;
  font-size: 15px;
}

.btnSecondary {
  background-color: #fff;
  border: 1.5px solid $clearBlue;
  font-family: "PT Sans";
  color: $clearBlue;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding: 6px 2rem;
  border-radius: 6px;
}

.btnSecondary:hover {
  background-color: $clearBlue;
  border: 1.5px solid $clearBlue;
  color: #fff;
}

.btnSecondary:disabled {
  background-color: #fff;
  border: 1.5px solid $clearGray;
  color: $clearGray;
  opacity: 1;
}
.btnSecondary:disabled img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(59%)
    hue-rotate(157deg) brightness(105%) contrast(62%);
}

.btnPrimaryAlt {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  font-family: "PT Sans";
  color: #fff;
  min-width: 110px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
}

.btnPrimaryAlt:hover {
  background-color: $darkerBlue;
  border: 1.5px solid $darkerBlue;
  color: #fff;
}

//button extra for modal
//111111111111111111111111111111111111
.buttonExtra1 {
  background-color: #fff !important;
  border: 1.5px solid $darkBlue !important;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: "PT Sans";
  color: $darkBlue !important;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  justify-content: center;
}
.buttonExtra1:hover {
  background-color: $darkBlue !important;
  border: 1.5px solid $darkBlue !important;
  color: #fff;
}
.buttonExtra1:hover > img {
  filter: brightness(0) invert(1);
}
.buttonExtra1:disabled {
  background-color: #fff !important;
  border: 1.5px solid $clearGray !important;
  color: $clearGray;
  opacity: 1;
}
.buttonExtra1:disabled > {
  filter: brightness(0) saturate(100%) invert(83%) sepia(0%) saturate(3%)
    hue-rotate(198deg) brightness(101%) contrast(91%);
}

//2222222222222222222222222222222222222
.buttonExtra2 {
  background-color: #fff !important;
  border: 1.5px solid $clearBlue !important;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: "PT Sans";
  color: $clearBlue !important;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}
.buttonExtra2:hover {
  background-color: $clearBlue !important;
  border: 1.5px solid $clearBlue !important;
  color: #fff !important;
}

//3333333333333333333333333333333333333333333333
.buttonExtra3 {
  background-color: $darkBlue;
  border: 1.5px solid $darkBlue;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: "PT Sans";
  color: #fff;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}
.buttonExtra3:hover {
  background-color: $darkerBlue;
  border: 1.5px solid $darkerBlue;
  color: #fff;
}

.buttonExtra2:disabled,
.buttonExtra3:disabled {
  background-color: $clearGray;
  border: 1.5px solid $clearGray;
  color: #fff;
  opacity: 1;

  font-family: "PT Sans";
  color: #fff;
  min-width: 130px;
  font-weight: 700;
  font-size: 15px;
  line-height: 19.41px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.buttonExtra2:hover > img,
.buttonExtra2:disabled > img,
.buttonExtra3 > img {
  filter: brightness(0) invert(1);
}

.buttonExtra1 > img,
.buttonExtra2 > img,
.buttonExtra3 > img {
  height: 15px;
  vertical-align: middle;
  margin: auto 8px auto 0px;
}

//end extra buttons

.deleteicon {
  position: relative;
  flex: 1;
  display: flex;
}
.deleteicon span {
  position: absolute;
  display: block;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: $clearBlue;
  cursor: pointer;
  border-radius: 50%;
}
.deleteicon input {
  flex: 1;
}
.warningIcon {
  background-image: url("../../assets/img/icons/AlertTriangle.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}
.deniedCross {
  //   background-image: url("../../assets/img/icons/FalseCross.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.checkMark {
  //   background-image: url("../../assets/img/icons/checkOnTable.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}
.shade {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.greenCheck {
  background-image: url("../../assets/img/icons/CheckMark.svg");
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.1rem;
  width: "-webkit-fill-available";
  margin: auto;
}

.alertTriangle {
  //   background-image: url("../../assets/img/icons/Alert.svg");
  background-repeat: no-repeat;
  width: "-webkit-fill-available";
  width: 1.5rem;
  height: 1.1rem;
  margin: auto;
}

.shade {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}
.titleDeference {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #005dbf;
}
.titleTax {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #005dbf;
}
.valorDeference {
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #00a551;
}

.labelIntoButton {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #58595b;
}

.darkGrayText {
  color: $darkGray;
}
.horizontalBottomLine {
  color: $darkGray;
  width: 92%;
  margin-left: 4%;
}
.articleSearch {
  transition: transform 0.3s;
  width: 25px;
  background: transparent;
  border: none;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: #58595b;
}

.valorDeference {
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #00a551;
}

.labelIntoButton {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #58595b;
}

.circleAddButton {
  width: 16px;
  height: 16px;
  background-size: contain;
  cursor: pointer;
  //   background-image: url("../../assets/img/icons/addbutton.svg");
}
// .circleAddButton:hover {
//   background-image: url("../../assets/img/icons/addbuttonhover.svg");
// }

.warningIcon {
  background-image: url("../../assets/img/icons/AlertTriangle.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}
.deniedCross {
  //   background-image: url("../../assets/img/icons/FalseCross.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1rem;
  height: 1rem;
  margin: auto;
}
.btnNewHover {
  cursor: pointer;
  filter: grayscale(1);
  align-self: center;
  padding-left: 10px;
}
.btnNewHover:hover {
  filter: none;
}
.inputSearch {
  background-color: #005dbf1a;
  border: none;
  border-radius: 10px;
}
.checkMark {
  //   background-image: url("../../assets/img/icons/checkOnTable.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}
.subtitle {
  font-family: $font-Secular;
  color: $darkBlue;
  font-weight: Bold;
  font-size: 15px;
  line-height: 19px;
  padding: 0 0 0 0;
  margin: 0 0 9px 0;
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

.circleAddButton {
  width: 16px;
  height: 16px;
  background-size: contain;
  cursor: pointer;
  //   background-image: url("../../assets/img/icons/addbutton.svg");
}
// .circleAddButton:hover {
//   background-image: url("../../assets/img/icons/addbuttonhover.svg");
// }
.darkBlueText {
  color: $darkBlue;
}
.clearBlueSearch {
  border: rgba(0, 93, 191, 0.1);
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 6px;
  width: 70%;
  height: 1.8rem;
  padding-left: 5px;
}
.verticalLine {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: 100vh;
  width: 1px;
}
.bgClearBlue {
  background-color: $bgFormBlue;
}
.titlePayrollProvision {
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: #58595b;
  font-size: 28px;
  line-height: 30px;
}
.subTitlePayrollProvision {
  color: #005dbf;
  font-size: 30px;
  font-weight: bold;
}
.clearBlueText {
  color: #4d84fe;
}
.bgColorClearBlue {
  background-color: $bgFormBlue;
  // background-color: 'red';
}
.emptyCircle {
  width: 25px;
  height: 25px;
  border: 2px solid green;
  opacity: 0.3;
  border-radius: 100%;
}
.x {
  width: 10%;
  align-self: self-end;
  cursor: pointer;
  border-radius: 100%;
}
.noBorderInput {
  border: none !important;
  background-color: $white;
  border-radius: 15px;
}
.noBorderInput:focus {
  outline: none;
}
.blackGrayBoldFont {
  font-family: PT Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #58595b;
}

.slideEffectbtn {
  background-color: white;
  // margin-left: 15px;
  border-radius: 15px 15px 0 0;
  border: 1 px solid #8080805e;
  border-bottom: none;
}
.fw700End {
  font-size: 13px;
  align-items: flex-start;
}

.timeLineContainer {
  list-style-type: none;
}
.timeLineItem {
  position: relative;
  margin: 0;
  padding-bottom: 1em;
  padding-left: 20px;
}

.timeLineItem:before {
  content: "";
  background-color: $darkBlue;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 6px;
  width: 3px;
}

.timeLineItem:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%2962ff' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
  position: absolute;
  left: 0;
  height: 15px;
  width: 15px;
}
.clearBlueBorder {
  border: 0.1px solid $clearBlue;

  border-radius: 5px;
}
.tableScroll {
  height: 24rem;
  overflow: auto;
  margin-top: 20px;
}
// .tableScrollBody {
//   height: 300px;
//   overflow-y: scroll;
//   display: block;
// }
.bgBlueHover:hover {
  background-color: $bgFormBlue;
  border-radius: 5px;
}
.bgBlueHover > b > span:hover {
  color: $darkBlue;
}
.noBorderFocus:focus {
  outline: 0px;
}
.accordinElipsis {
  width: 215px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rwoReport {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
  // --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
}

.cardBoxContainer {
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  padding: 10px;
  width: 100%;
}
.cardBoxText {
  font-family: "PT Sans Caption";
  font-size: 13px;
  line-height: 17px;
  color: #58595b;
  margin: 0 0 8px 0;
}
.cardBoxTitle {
  font-family: "PT Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #005dbf;
  margin: 0 0 8px 0;
}
.cardBoxBottomText {
  font-family: "PT Sans";
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #58595b;
  margin: 0 16px 0 0;
}

.blueBtnHover {
  cursor: pointer;
  // filter: grayscale(1);
  color: $clearBlue;
  align-self: center;
  padding-left: 10px;
  border: 1px solid $clearBlue;
  background-color: $bgFormBlue;
  // background-color: black;
}
.blueBtnHover:hover {
  background-color: $tenueBlue;
}
.currentBtnStyle {
  cursor: pointer;
  // filter: grayscale(1);
  color: $clearBlue;
  align-self: center;
  padding-left: 10px;
  border: 1px solid $darkBlue;
  background-color: $white;
  // background-color: black;
}

.ellipsis {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blueOutline {
  color: $darkBlue;
  background-color: #fff;
  border-color: $darkBlue;
}

.blueOutline:hover {
  color: 2;
  background-color: $darkBlue;
  border-color: $darkBlue;
}

.scrollThis {
  height: 60vh;
  overflow: auto;
}
.grayBorderBottom {
  border-bottom: 1px solid #dce1e3;
  margin-bottom: 10%;
}

.labelFont {
  font-size: 0.8rem;
  color: #005dbf;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}
.labelFontGray {
  font-size: 0.8rem;
  color: #58595b;
  font-family: "PT Sans Caption";
  line-height: 0.971rem;
  font-weight: 400;
}
.profileContainer {
  width: 135px;
  height: 135px;
  border-radius: 8%;
  border: 1px solid $darkBlue;
  object-fit: "cover";
  // filter: drop-shadow(0 0 8px #ff5722);
}

.f13 {
  font-size: 13px;
}

.f12 {
  font-size: 12px;
}
.iconWrap:hover > img {
  filter: brightness(0) saturate(100%) invert(17%) sepia(68%) saturate(4437%)
    hue-rotate(201deg) brightness(98%) contrast(100%);
}
.mLeft2 {
  margin-left: 2.8%;
}
.f95pt {
  font-size: 9.5pt;
}
.weirdBackGround {
  background: rgb(239, 244, 251);
  background: linear-gradient(
    90deg,
    rgba(239, 244, 251, 1) 82.5%,
    rgba(239, 244, 251, 1) 69%,
    rgba(255, 254, 254, 0) 69%,
    rgba(244, 238, 238, 0) 100%
  );
}
.weirdBackGround2 {
  background: rgb(239, 244, 251);
  background: linear-gradient(
    90deg,
    rgba(239, 244, 251, 1) 93%,
    rgba(239, 244, 251, 1) 69%,
    rgba(255, 254, 254, 0) 69%,
    rgba(244, 238, 238, 0) 100%
  );
}
.f16 {
  font-size: 16px;
}
.f15 {
  font-size: 15px;
}
.f14 {
  font-size: 14px;
}

.genericGrayContainer {
  background-color: $tableEven;
  border-radius: 10px;
  padding: 15px 20px;
}

.orangeText {
  color: $orange;
}

.blueCheck {
  //   background-image: url("../../assets/img/icons/blueCheck.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.2rem;
  margin: auto;
}

.clearBlueSearch {
  border: rgba(0, 93, 191, 0.1);
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 6px;
  width: 70%;
  height: 1.8rem;
  padding-left: 5px;
}
.bdRadiusTabsTop {
  border-radius: 1rem 1rem 0 0;
}
.bdRadiusZeroEight {
  border-radius: 0.8rem;
}
/* -------------------------------------------------------------------------- */
/*                              VERTICAL TIMELINE                             */
/* -------------------------------------------------------------------------- */
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: $darkBlue;
  top: 0;
  bottom: 0;
  left: 40%;
  margin-left: -3px;
  // height: 15vh;
  // height: auto;
}

/* Container around content */
.timeLineContainer {
  padding: 0px 10px;
  position: relative;
  background-color: inherit;
  width: 40%;
}

/* The circles on the timeline */
.timeLineContainer::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  right: -6px;
  background-color: white;
  border: 2px solid $darkBlue;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the timeLineContainer to the left */
.left {
  left: 0;
  text-align: end;
}

/* Place the timeLineContainer to the right */
.right {
  left: 50%;
  margin-left: 18rem;
}

/* Add arrows to the left timeLineContainer (pointing right) */
// .left::before {
//   content: " ";
//   height: 0;
//   position: absolute;
//   top: 22px;
//   width: 0;
//   z-index: 1;
//   right: 30px;
//   border: medium solid white;
//   border-width: 10px 0 10px 10px;
//   border-color: transparent transparent transparent white;
// }

/* Add arrows to the right timeLineContainer (pointing left) */
// .right::before {
//   content: " ";
//   height: 0;
//   position: absolute;
//   top: 22px;
//   width: 0;
//   z-index: 1;
//   left: 30px;
//   border: medium solid white;
//   border-width: 10px 10px 10px 0;
//   border-color: transparent white transparent transparent;
// }

/* Fix the circle for timeLineContainers on the right side */
.right::after {
  left: -9px;
}

/* The actual content */
.content {
  padding: 0px 10px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width timeLineContainers */
  .timeLineContainer {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeLineContainer::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right timeLineContainers behave like the left ones */
  .right {
    left: 0%;
  }
}

/* ------------------------------------ - ----------------------------------- */
.rotate270Deegrees {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(270deg);
}

.simpleDropDown {
  border: none;
  background-color: white;
  color: #58595b;
}

.customTitle[title]:hover::after {
  background-color: red;
  content: attr(title);
  position: absolute;
  top: -100%;
  left: 0;
}

.noTextDecoration {
  text-decoration: none;
}

.backToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 20px;
  width: 40px;
  background: $bgFormBlue;
  color: $darkBlue;
  cursor: pointer;
  border-radius: 50%;

  border: none;
  box-shadow: 0 5px 10px #ccc;
}

// .backToTop:hover {
//   background: $clearBlue;
// }
.Search {
  border: 1px solid rgba(0, 93, 191, 0.1);
  background-color: rgba(0, 93, 191, 0.1);
  border-radius: 5px;
  width: 100%;
  padding: 0 0.5rem;
  height: 30px;
  color: #005dbf;
  margin-top: -2px;
}
.Search::placeholder {
  color: #005dbf70;
}
.inputs {
  color: #005dbf;
  border: 1px solid;
  border-radius: 5px;
}
.bgDisabled {
  background-color: #dee0e3c4;
}

.scrollThis {
  height: 60vh;
  overflow: auto;
}
@import "../../styleConstants.scss";

.popupContent{
    // border: 1px solid $darkBlue;
}

.popUpUl{
    margin: 0  ;
    list-style: none;
    padding-left: 0 ;    
}
.popUpUl li:hover{
    background-color:#005DBF;
    color:#fff;
}


.popUpLiEdit,
.popUpLiActive, 
.popUpLiActiveYellow,
.popUpLiUpdate,
.popUpShowDetails,
.popUpLiDelete,
.popUpLiActiveRed,
.popUpLiDataSheet{
    padding-left: 1em;
    text-indent: -1em;
    margin-top: .3rem;
    margin-bottom: .3rem;
    font-size: .8rem;
    //cursor: pointer;
}

// .popUpLiEdit:hover,
// .popUpLiUpdate:hover,
// .popUpShowDetails:hover,
// .popUpLiActive:hover,
// .popUpLiDelete:hover,
// .popUpLiActiveRed:hover{
//     background-color:#005DBF;
//     color:#fff;    
// }

.popUpLiEdit:hover::before,
.popUpLiUpdate:hover::before,
.popUpShowDetails:hover::before,
.popUpLiActive:hover::before,
.popUpLiDelete:hover::before,
.popUpLiActiveRed:hover::before,
.popUpLiDataSheet:hover::before{
    filter:grayscale(100%) invert(100%);
}

// .popUpLiEdit:hover,
// .popUpLiActive:hover, 
// .popUpLiActiveRed:hover{
// }

.popUpLiActive:before {
    content: "";
    display: inline-block;
    background: $greenEnabled;
    border-radius: 50%;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
    }

.popUpLiActiveRed:before {
    content: "";
    display: inline-block;
    background: $redDisabled;
    border-radius: 50%;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
    }

.popUpLiActiveYellow:before {
    content: "";
    display: inline-block;
    background: $yellowAux;
    border-radius: 50%;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
}

.popUpLiEdit:before {
    content: "";
    background-image: url("../../assets/img/icons/editar.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
}
.popUpLiUpdate:before {
    content: "";
    background-image: url("../../assets/img/icons/deshacer.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .7rem;
    height: .65rem;
    margin-right: 5px;
}
.popUpLiUpdate:hover{
    background-color:#005DBF;
    color:#fff;
}
.popUpShowDetails:before {
    content: "";
    background-image: url("../../assets/img/icons/lupaTransparent.svg");
    background-repeat: no-repeat;
    background-size:unset;
    background-position:left;
    display: inline-block;
    width: .7rem;
    height: .65rem;
    margin-right: 5px;
}
.popUpLiDelete:before {
    content: "";
    background-image: url("../../assets/img/icons/x.svg");
    background-repeat: no-repeat;
    background-size:unset;
    background-position:left;
    display: inline-block;
    width: .7rem;
    height: .65rem;
    margin-right: 5px;
}
.popUpLiDataSheet:before{
    content: "";
    background-image: url("../../assets/img/icons/subir.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: .6rem;
    height: .6rem;
    margin-right: 5px;
}
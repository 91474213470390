@import "../../styleConstants.scss";
@font-face {
    font-family: "PT Sans Caption";
    src: url("../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf") format('truetype');
}

@font-face {
    font-family: "PT Sans Caption";
    src: url("../../assets/fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf") format('truetype');
}

.genericTable{
    font-family: "PT Sans Caption";
    font-weight: 400;
    font-style: normal;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    border-spacing: 0;
}

.genericTable> tbody> tr> td {
    border: none;
    padding: .4rem 0;
    text-transform:lowercase;
    background-color: $white;
    color: $tableText;
    font-size: 13px;
    line-height: 16.8px;
    font-weight: 500;
    vertical-align: middle;
}
.genericTable> tbody> tr> td::first-letter{
    text-transform:capitalize;
}

.genericTable> tbody> tr:nth-of-type(even)>td {
    background-color: $tableEven;
}

.genericTable> tbody> tr:hover>td {
    background-color: $tableHover;
    transition: 0.3s all ease-in-out;
}

.genericTable> tbody> tr:last-child> td:first-child{
    border-bottom-left-radius: 10px;
}
.genericTable> tbody> tr:last-child> td:last-child{
    border-bottom-right-radius: 10px;
}


.thead > tr > th{
    font-size: 15px;
    line-height: 19.4px;
    padding: .5rem 0;
}

.theadLight{
    color: $white;
    background-color: $clearBlue;
}

.theadDark{
    color: $white;
    background-color: $darkBlue;
}


.thead > tr > th:first-child{
    border-top-left-radius: 10px;
}
.thead > tr > th:last-child{
    border-top-right-radius: 10px;
}


.genericTable> tbody> tr.orange>td {
    background-color: $tableAddition;
}

.genericTable> tbody> tr.orange:hover>td {
    background-color: $tableAdditionHover;
    transition: 0.3s all ease-in-out;
}

.genericTable> tbody> tr.transladar>td {
    background-color: $tableTransladar;
}

.genericTable> tbody> tr.transladar:hover>td {
    background-color: $tableTransladarHover;
    transition: 0.3s all ease-in-out;
}

// FOR TABLE TO NEED RECORDS IN LOWER CASE
.genericTableLower{
    font-family: "PT Sans Caption";
    font-weight: 400;
    font-style: normal;
    width: 100%;
    margin-top: 1rem;
    text-align: left;
    border-spacing: 0;
}

.genericTableLower> tbody> tr> td {
    border: none;
    padding:3px;
    background-color: $white;
    color: $tableText;
    font-size: 13px;
    line-height: 16.8px;
    font-weight: 500;
    vertical-align: middle;
}
.genericTableLower> tfoot> tr> td {
    border: none;
    padding:4px;
    background-color: $white;
    color: $tableText;
    font-size: 13px;
    line-height: 16.8px;
    font-weight: 500;
    vertical-align: middle;
}

.genericTableLower> tbody> tr:nth-of-type(even)>td {
    background-color: $tableEven;
}

.genericTableLower> tfoot> tr:nth-of-type(odd)>td {
    background-color: $tableEven;
}

.genericTableLower> tbody> tr:hover>td {
    background-color: $tableHover;
    transition: 0.3s all ease-in-out;
}

.genericTableLower> tbody> tr:last-child> td:first-child{
    border-bottom-left-radius: 10px;
}
.genericTableLower> tfoot> tr:last-child> td:last-child{
    border-bottom-right-radius: 10px;
}

.genericTableLower> tbody> tr.orange>td {
    background-color: $tableAddition;
}

.genericTableLower> tbody> tr.orange:hover>td {
    background-color: $tableAdditionHover;
    transition: 0.3s all ease-in-out;
}

.genericTableLower> tbody> tr.transladar>td {
    background-color: $tableTransladar;
}

.genericTableLower> tbody> tr.transladar:hover>td {
    background-color: $tableTransladarHover;
    transition: 0.3s all ease-in-out;
}



.genericTableLower> tfoot> tr:hover>td {
    background-color: $tableHover;
    transition: 0.3s all ease-in-out;
}

.genericTableLower> tfoot> tr:last-child> td:first-child{
    border-bottom-left-radius: 10px;
}
.genericTableLower> tfoot> tr:last-child> td:last-child{
    border-bottom-right-radius: 10px;
}

.genericTableLower> tfoot> tr.orange>td {
    background-color: $tableAddition;
}

.genericTableLower> tfoot> tr.orange:hover>td {
    background-color: $tableAdditionHover;
    transition: 0.3s all ease-in-out;
}



@import "../../styleConstants.scss";

.wrapper{
    display: flex;
    flex-direction: row;
}

.wrapperReverse{
    display: flex;
    flex-direction: row-reverse;
}

.itemClass{
    //background-color: $tableEven;
    padding: 3px 8px;
    margin: 0 .5rem;
    width: 25px;
    height: 25px;
    font-family: "PT Sans Caption";
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
}

.itemClass:hover,
.itemClass[class*="active"]:hover{
    background-color: $paginationHover;
}

.itemClass[class*="active"]{
    background-color: $paginationSelected;
}

.itemClass a{
    text-decoration: none;
    color: $tableText;
}

.itemClass[class*="active"] a{
 color: $darkBlue;
}

.itemClass[class*="active"].iconClear a{
    color: $clearBlue;
}

.itemClassPrev{
    background-image: url("../../assets/img/icons/backPlay.svg");
}
.itemClassNext{
    background-image: url("../../assets/img/icons/play.svg");
}
.itemClassFirst{
    background-image: url("../../assets/img/icons/primero.svg");
}
.itemClassLast{
    background-image: url("../../assets/img/icons/ultimo.svg");
}

.itemClassPrev.iconClear{
    background-image: url("../../assets/img/icons/backPlayClear.svg");
}
.itemClassNext.iconClear{
    background-image: url("../../assets/img/icons/playClear.svg");
}
.itemClassFirst.iconClear{
    background-image: url("../../assets/img/icons/primeroClear.svg");
}
.itemClassLast.iconClear{
    background-image: url("../../assets/img/icons/ultimoClear.svg");
}

.filterClearBlue{
    filter: brightness(0) saturate(100%) invert(43%) sepia(100%) saturate(1319%) hue-rotate(171deg) brightness(95%) contrast(104%);
}

.itemClassPrev:not(.disabled):hover,
.itemClassNext:not(.disabled):hover,
.itemClassFirst:not(.disabled):hover, 
.itemClassLast:not(.disabled):hover
{
    opacity: 0.5;
}

.itemClassPrev a,
.itemClassNext a,
.itemClassFirst a, 
.itemClassLast a
{
    color:transparent
}

.paginationText{
    display: inline-flex;
    flex:1;
}
.activeClass{
    background-color: $paginationSelected;
}
@import '../../index.scss';
@import "../../styleConstants.scss";
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption:400,700');

.headerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 5px solid $darkBlue;
    max-height: 80px;
}
.focaLogoHeader{
    width: 100%;
    max-width: 200px;
    height: 100%;
}
.userImgHeaderButton{
    min-width: 208px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid $clearGray;
    margin:6px 20px 6px auto;
    display: flex;
}
.userImgHeaderButton:hover{
    background-color: rgba(0, 63, 128, .1);
}
.userImgHeaderIcon{
    
    width: 22px;
    height: 22px;
    margin:13px 0 13px 20px ;
    filter: brightness(0) saturate(100%) invert(22%) sepia(44%) saturate(3899%) hue-rotate(199deg) brightness(98%) contrast(93%);
}
.userImgHeaderText{
    flex:1;
    margin: 13px 10px;
    font-family: "PT Sans Caption";
    font-size: 15px;
    line-height: 19px;
    color: #58595B;
}
.userImgHeader{
    width: 40px;
    height: 40px;
    border:1px solid $clearBlue;
    border-radius: 100%;
    margin:4px 10px 4px 0;
}

.profilepicWrapper{
    position: relative;
    margin: 0 auto;
}

.profilepic{
    width: 64px;
    height: 64px;
    border:1px solid $clearBlue;
    border-radius: 100%;
}

.changepfp{
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: absolute;
    bottom: 1px;
    padding: 4px;
    background: #fff;
    right: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}

.userName{
    font-family: "PT Sans";
    font-weight: Bold;
    font-size: 15px;
    line-height: 19px;
    color:#58595B;
    text-align: center;
    margin: 6px auto;
}

.userAccountProfile{
    font-family: "PT Sans Caption";
    font-weight: Bold;
    font-size: 15px;
    line-height: 19px;
    color:#CECECE;
    text-align: center;
    margin-bottom: 8px;
}

.optionText{
    font-family: "PT Sans Caption";
    font-size: 12px;
    line-height: 16px;
    color: #58595B;
    margin: 4px auto 4px 0;
}
.optionText:hover{
    color: $clearBlue;
}


.hoverPointer:hover{
    cursor: pointer;
}
.popUpUserProfile{
    position: absolute;
    padding: 1rem;    
    z-index: 2000;

    top: 4rem;
    right:20px;
    width: 206px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: none;
    border-radius: 10px;
}
.thatTinyArrowUpThere{
    position: absolute;
    top: -8px;
    right: 20px;
}

.containerInputHeader{
    min-width: 17rem;
}
.inputTextHeader{
    margin-left: 2rem !important;
    font-weight: 700;
    color:$darkBlue;
    overflow: hidden;
}
.inputEditMaxWidth{
    //max-width: 11rem;
    flex:1;
}
.inputUserIconEditProfile{
    position: absolute;
    transform: scale(3);
    z-index: 2002;
    left: 1rem;
    top: 1.5rem;
}
.inputEditIconProfile{
    position: absolute;
    transform: scale(3);
    right: 1rem;
    top: 1.5rem;
}

.borderEditInput{
    width: 80px;
}
.borderEditInput::before{
    content: '';
    border-left: 1px solid $clearBlue;
    position: absolute;
    right:20%;
    height: 100%;
}

.inputBlueBorder{
    border: 1px solid $clearBlue;
    border-radius: 10px;
    color: $darkBlue;
    font-weight: 700;
}

.linkText{
    text-decoration: none;
}



.auydaLogo{
    width: 126px;
    height: 44px;
    margin: 0 auto;
    cursor: default;
}

.ayudaSub{
    font-family: "PT Sans";
    font-weight: Bold;
    font-size: 18px;
    line-height: 23px;
    color:#58595B;
    text-align: center;
    margin: 20px auto 5px auto;
    cursor: default;
}

.ayudaRow{
    width: 200px;
    display: flex;
    margin: 14px auto;
    cursor: default;
}

.ayudaRow > img{
    width: 20px;
    height: 20px;
    margin-right: 13px;
    cursor: default;
}

.ayudaRow > p{
    flex: 1;
    font-family: "PT Sans";
    font-size: 13px;
    line-height: 17px;
    color:#58595B;
    margin: 0;
    cursor: default;
}

@import "../../styleConstants.scss";


.containerBackground{
    width:100%;
    height:100vh;
    align-items: center;
    display: flex;
    flex-direction:column;
}

.containerLogin{
    padding:2rem;
    width: calc(398px*1.5);
    margin: auto;    
    text-align: center;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);;
}

.btnLogin{
    background-color: $clearBlue;    
    width:calc(273px * 1.5);
    border-radius:10px;
    height:4rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    text-align: center;
    font-size:1.45rem;
    margin: 1rem 0;
    border:none;
}
.containerInputLogin{
    margin:1rem auto;
    background-color: #fff;
    border: 1.5px solid #01A0F6;
    border-radius: 7px;
    width:calc(273px * 1.5);
    height:48px;
    overflow:hidden;
}
.inputLogin{
    border: none;
    width:85%;
    height:45px;
    margin: auto 10px;
}
.inputLogin:focus-visible, .inputLogin:focus{
    border: none !important;
    background-color: #fff !important;
    outline-style: none;
}
.clock{filter:grayscale(1);}
.clock:hover{filter:none;}
.containerHeadDeliveries{position: relative;}
.containerHeadDeliveries{font-size: 13px;}
.containerHeadDeliveries::before{
    content:"";
    background: #005DBF;
    opacity: 0.05;
    width:100%;
    height:100%;
    position: absolute;
    padding:16px;    
    border-radius: 10px;
}   
.inputTextTable{
    width:90%;
    border:none;
    background: transparent;
    text-align:center;
}
.inputTextTable:disabled{
    border: none;
}
.inputTextTable:focus-visible,.inputTextTable:focus{
    border:none;
    outline: none;
}
.groupIcons{    
    display:flex;
    justify-content:flex-end;
    align-items:flex-end;
    flex:2;
    margin-right: 0.1rem;
}
.groupIcons img {
    filter:grayscale(1);
}
.groupIcons img:hover {
    filter:none;
    cursor:pointer;
}
.discountInput{
    // margin: 0 1rem;
    display:flex;
    flex-direction: row-reverse;
}
.discountInputText{    
    border-radius: 10px;
    color:$darkGray;
    position:relative;
    border:none;
    background-color:$backgroundDarkBlue;
    transition: 0.5s all ease-in-out;
}
.discountInputText:disabled{
    border: none;
}
.discountInputText:focus-visible{
    border:1.5px solid $darkBlue;
}
.collapsedDiscount{
    // transform:translateX(200%);
    width: 0%;
    background-color:transparent;
    // opacity:0;
    // visibility: hidden;
}
.expandDiscount{
    // opacity:1;
    // visibility: visible;
    // transform:translateX(0%);
    width:100%;
}
.observationsArea{
    max-height: 4rem;
    border:1px solid $darkBlue;
    border-radius: 6px;
}
.containerDropzone{
    margin:1rem 0;
    width:40%;
    align-items:center;
}
.containerDropzone:hover{
    cursor:pointer;
}
.dropzoneSignature{    
    width: 100%;
    height: 4rem;
    border-radius: 10px;
    position:relative;
    display:flex;
    justify-content:center;
    text-align:center;
    border-radius: 10px;
    border: 1.5px solid rgba(0, 93, 191, 0.1);
    background-color:rgba(0, 93, 191, 0.1);
}
.footerTableContainer{
    width:15%;
    list-style: none;
    // margin-right: 2rem;
    font-size: 0.8rem;
}
.footerTableContainer tbody tr td{
    padding:3px;
}
.footerTableContainer tbody tr:nth-child(1), tbody tr:nth-child(3){
    background-color:$tableEven;
}
.buttonDisabled{
    background:#CECECE;
    opacity:0.5;
    color:#fff;
}
.inputDisabled:disabled{
    background: #F5F7FA !important; 
    opacity:1 !important;    
    border: 1px solid $darkBlue !important;
}
.container-fluid > *{
    color:$darkGray;
}
.actionsIcons:hover{
    background-color:rgba(0, 93, 191, 0.1);
    border-radius: 100%;    
}
.actionsIcons{
    // padding:5px;
    margin:0 3px 2px 0;    
}
.actionsIconsTrash:hover{
    background-color:rgba(0, 93, 191, 0.1);
}
.actionsIconsTrash{
    // padding:5px;
    margin:0 3px 2px 0;    
}
@import "../../styleConstants.scss";

.container1{
    display: flex;
    flex-direction: row;
}

.throwThisToTheBottom{
    display: flex;
    flex-direction: column-reverse;
}

//zone of new angie made tables
.container2{
    //flex:2;
    //margin-top:15px;
    display: flex;
    flex-direction: column;
}

.textarea {
    resize: none;
    
}
.subtitle{
    margin: 16px 0 0 0;
    color:#005DBF;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}

.styleSelect{
    background: none; 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
}

.container3{
    flex:1;
    display: flex;
    flex-direction: column;
}

.ItemTiny{
    flex:2;
    display: flex;
    flex-direction: column;
    width: 27px;
}

.ItemSmall{
    flex:6;
    display: flex;
    flex-direction: column;
}

.ItemSmall_new{
    flex:3;
    display: flex;
    flex-direction: column;
}

.Item{
    flex:0.6;
    display: flex;
    flex-direction: column;
}

.imagePadding{
    margin-top: 8px;
}
.imagePaddingNew{
    margin-top: 25px;
}


.letterPadding{
    padding-left: 8px;
    color: #005DBF;
}

.btn{
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(20%);
}

.btnNew{
    cursor: pointer;
    filter: grayscale(100%)
}

.btnNew:hover{
    filter: none;
}

.buttonCreatCount{
    flex-direction: row;
    float: right;
    width: 185px;
    background-color:#F5F7FA;
    height: 30px;
    display: inline-flex;
    border-radius: 10px;
    justify-content: space-evenly;
}
.Boton{
    cursor: pointer;
    flex-direction: row;
    width: 64px;
    background-color:rgba(0, 93, 191, 0.1);
    height: 24px;
    border-radius: 10px;
}

.filesDir{
    flex-direction: row;
    display: inline-flex;
}
.position{
    position: relative;
}

.buttonCreatCount>p{
    size: 76px;
    line-height:16px;
    margin-bottom: 0;
    color: #005DBF;
    font-Weight:700;
}

.deleteicon {
    position: relative;
    flex: 1;
    display: flex;
}
.deleteicon span {
    position: absolute;
    display: block;
    top: -6px;
    right: -6px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    text-align: center;
    color: #fff;
    background: $darkBlue;
    cursor: pointer;
    border-radius: 50%;
}
.deleteicon input {
    flex:1;
}

.imagePaddingType{
    width: 20px;
    height: 20px;
    margin: 5px 0px 0px 6px;
}


.buttonCreatCount>img{
    padding: 0px 0px 0px 0px;
    margin-top: 6px;
}
.ellipsis{
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.labelNew{
    font-weight: 700;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
    margin-bottom: 1.5px;
    align-content: left ;
    color: #005DBF;
    padding: 6px 1px 3px 4px;
}

.justify{
    justify-content: space-between
}


.btn:hover{
    filter: none;
}

.ItemTiny_new{
    flex:2.36;
    display: flex;
    flex-direction: column;
    width: 27px;
}

.crudModalLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: $darkBlue;
}
.mainRow{
    display: inline-flex;
    width: 100%;
    vertical-align: middle;
    justify-content: space-between;
    padding: 0 .5rem;
}

.crudModalInputDate{
    font-size: 1vw;
    flex: 1 1 auto;
}
.backgroundModal{
    background : rgba(0, 93, 191, 0.05);
    border-radius: 10px;
}
.ItemTiny2{
    flex:1.33;
    display: flex;
    flex-direction: column;
    width: 25px;
}

.ItemTiny3{
    flex:2;
    display: flex;
    flex-direction: column;
}


.ItemMedium{
    flex:1;
    display: flex;
    flex-direction: row;
    width: 27px;
}
.Aling{
    display:flex ;
    flex:1;
    margin-left: auto;
    position: relative;
}
.inputMargin{
    margin-right:8px

}
.inputMarginNew{
    margin-right:15px,

}
.negativeMargin{
    margin-left: -13px;
}

.inputMarginleft{
    margin-left:15px;
    margin-right:15px
}
.inputMarginleftNew{
    margin-left:15px;
    margin-right:15px
}

.paddingButton{
    padding-bottom: 15px;
}

.btnPrimary{
    background-color: #fff !important;
    border: 1.5px solid $clearBlue !important;
    color: $clearBlue !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19,41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnPrimary:hover{
    background-color: $clearBlue !important;
    border: 1.5px solid $darkBlue !important;
    color:#fff !important;
}

.btnPrimary:disabled{
    background-color: $clearGray;
    border: 1.5px solid $clearGray;
    color:#fff;
    opacity: 1;
}

.btnSecondary{
    background-color:$darkBlue  !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19,41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnSecondary:hover{
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color:#fff !important;
}

.btnSecondary:disabled{
    background-color: #fff !important;
    border: 1.5px solid $clearGray !important;
    color:$clearGray !important;
    opacity: 1;
}

.ItemCheckbox{
    display: flex;
    margin-top: 24px;
    //flex-direction: column-reverse;
}

.registerInputs{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
}
.selectRegister{
    height: 27px;
    width: 100%;
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    flex: 1;
}

.greyRow{
    display:flex;
    flex-direction: row;
    background-color: #ddd;
    border-radius: .5rem;
    margin-top:1rem;
    padding: .5rem;
    padding: .5rem 2rem;
}

.textArea{
    height: 32px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
}

.textArea_new{
    height: 27px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
}



.bottomRow{
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 .5rem;
}

.bottomRow> Button{
    margin-left: 12px;
}

.tableButton{
    cursor: pointer;
    color: $tableText;
    text-decoration: none;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 12px;
    line-height: 16px;
}

.tableButton:hover{
    color: $darkBlue;
    padding-top: 0px;
    transition-duration: 0.2s;
}

.tableSlash{
    color: $tableText;
    font-size: 12px;
    line-height: 16px;
}

.traerReq{
    display: flex;
    min-width: 140px;
    height: 30px;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
    color: #697387;
    cursor: pointer;

    border-radius: 10px;
    margin: 0 0 0 auto;
}

.traerReq:hover{
    background-color: #F5F7FA;
}

.traerReq > p {
    flex: 1;
    margin: 8px 6px 4px 6px;
}

.traerReq:hover> p{
    color: #005DBF;
}

.traerReq:hover> img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}


.pseudoInput{
    padding: 1px;
    position: relative;
    cursor: pointer;
}

.pseudoInput>p{
    margin:0;
    color:#CECECE;
    font-size: 16px;
    line-height: 24px;
    height: 22px;
    padding-left: 8px;
    overflow: hidden;
}
.size{
    width: 32px;
    height: 32px;
}
.sizeNew{
    width: 25px;
    height: 25px;
}
.sizeNew2{
    width: 16px;
    height: 16px;
}


.popupReqActive::before{
    width: 12px;
    height: 12px;
    cursor:default;
}

.pseudoInput>img{
    position: absolute;
    top:2px;
    right:2px;
    width: 15px;
    height: 22px;
    margin: 0;
}

.urgente{
    color: #697387;
    line-height: 16px;
    font-size: 12px;
    display: inline;
}
.newArticle{
    margin: 0 5px;
    border: 1.5px solid $orange;
    border-radius: 5px;
    padding: 0 15px;
}

.adjustIcon{
    cursor: pointer;
    width: 20px;
    margin: 0;
}
.pointer{
    cursor: pointer;
}
.pointerNew{
    cursor: pointer;
    margin-top: -3px;
}


//added for article insert ts-147
.orangeIcon{
    fill: $orange;
    filter: saturate(3000%) hue-rotate(190deg);
}

.orangeBorder{
    border: 1.5px solid $orange;
    border-radius: 5px;
}

.orangeText{
    color: $orange;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
}

.orangeCheck{
    background-color: $orange;
    border-color: $orange;
}
@import "../../styleConstants.scss";

.container1{
    display: flex;
    flex-direction: row;
}

.throwThisToTheBottom{
    display: flex;
    flex-direction: column-reverse;
}

//zone of new angie made tables
.container2{
    //flex:2;
    //margin-top:15px;
    display: flex;
    flex-direction: column;
}

.ItemSmall1{
    flex:4.7;
    display: flex;
    flex-direction: column;
}

.crudModalLabelGris{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: $darkGray;
}

.registerInputsBlue{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
    color: #005DBF;
    font-weight: 700;
}

.registerInputsGris{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
    color:#58595B;
}

.textarea {
    resize: none;
    
}

.size{
    width: 32px;
    height: 32px;
}
.subtitle{
    margin: 16px 0 0 0;
    color:#01A0F6;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
}

.styleSelect{
    background: none; 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
}

.container3{
    flex:1;
    display: flex;
    flex-direction: column;
}

.ItemTiny{
    flex:2;
    display: flex;
    flex-direction: column;
    width: 27px;
}

.ItemSmall{
    flex:6;
    display: flex;
    flex-direction: column;
}

.inputAlling{
    flex-direction: column;
    align-items: flex-start;
}
.ItemSmall1{
    flex:4.7;
    display: flex;
    flex-direction: column;
}

.ItemSmall_new{
    flex:3;
    display: flex;
    flex-direction: column;
}

.Item{
    flex:0.58;
    display: flex;
    flex-direction: column;
}
.Item3{
    flex:0.62;
    display: flex;
    flex-direction: column;
}
.Item2{
    flex:0.53;
    //margin-right:3px;
    display: flex;
    flex-direction: column;
}

.imagePadding{
    margin-top: 8px;
}
.imagePaddingNew{
    margin-top: 25px;
}


.letterPadding{
    padding-left: 8px;
    color: #005DBF;
}

.btn{
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(20%);
}

.btnNew{
    cursor: pointer;
    filter: grayscale(100%)
}

.btnNew:hover{
    filter: none;
}

.imagePadding2{
    margin-top:-3px;

}


.buttonCreatCount{
    flex-direction: row;
    float: right;
    width: 185px;
    background-color:#F5F7FA;
    height: 30px;
    display: inline-flex;
    border-radius: 10px;
    justify-content: space-evenly;
}
.Boton{
    cursor: pointer;
    flex-direction: row;
    width: 64px;
    background-color:rgba(0, 93, 191, 0.1);
    height: 24px;
    border-radius: 10px;
}

.filesDir{
    flex-direction: row;
    display: inline-flex;
}
.position{
    position: relative;
}

.buttonCreatCount>p{
    size: 76px;
    line-height:16px;
    margin-bottom: 0;
    color: #005DBF;
    font-Weight:700;
}

.deleteicon {
    position: relative;
    flex: 1;
    display: flex;
}
.deleteicon span {
    position: absolute;
    display: block;
    top: -6px;
    right: -6px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    text-align: center;
    color: #fff;
    background: $darkBlue;
    cursor: pointer;
    border-radius: 50%;
}
.size{
    width: 32px;
    height: 32px;
}
.deleteicon input {
    flex:1;
}

.imagePaddingType{
    width: 20px;
    height: 20px;
    margin: 5px 0px 0px 6px;
}


.buttonCreatCount>img{
    padding: 0px 0px 0px 0px;
    margin-top: 6px;
}
.ellipsis{
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.labelNew{
    font-weight: 700;
    font-style : normal;
    font-size: 12px;
    line-height:15.53px;
    margin-bottom: 1.5px;
    align-content: left ;
    color: #005DBF;
    padding: 6px 1px 3px 4px;
}

.justify{
    justify-content: space-between
}


.btn:hover{
    filter: none;
}

.ItemTiny_new{
    flex:2.36;
    display: flex;
    flex-direction: column;
    width: 27px;
}

.crudModalLabel{
    font-size: .75rem;
    margin: .4rem .3rem 0 .3rem;
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    color: $darkBlue;
}
.mainRow{
    display: inline-flex;
    width: 100%;
    vertical-align: middle;
    justify-content: space-between;
    padding: 0 .5rem;
}

.crudModalInputDate{
    font-size: 1vw;
    flex: 1 1 auto;
}
.backgroundModal{
    background : rgba(0, 93, 191, 0.05);
    border-radius: 10px;
    padding-bottom: 16px;
    padding-left: 15px;
    margin-right: -20px;
    padding-right: 20px;
}

.backgroundModal_new{
    border-radius: 10px;
    padding-bottom: 16px;
    padding-left: 15px;
    margin-right: -20px;
    padding-right: 20px;
}



.ItemTiny2{
    flex:1.33;
    display: flex;
    flex-direction: column;
    width: 25px;
}

.ItemTiny_cantidad{
    flex:1.09;
    display: flex;
    flex-direction: column;
    width: 25px;

}
.ItemTiny4{
    flex:3;
    display: flex;
    flex-direction: column;
    width: 25px;
}

.ItemTiny3{
    flex:2;
    display: flex;
    flex-direction: column;
}


.ItemMedium{
    flex:1;
    display: flex;
    flex-direction: row;
    width: 27px;
}
.Aling{
    display:flex ;
    flex:1;
    margin-left: auto;
    position: relative;
}
.inputMargin{
    margin-right:8px

}
.inputMarginNew{
    margin-right:15px,
}
.inputMarginleft_new{
    margin-left:15px
}
.negativeMargin{
    margin-left: -13px;
}
.line{
    align-self: flex-end;
}
.inputMarginleft{
    margin-left:15px;
    margin-right:15px
}
.inputMarginleftNew{
    margin-left:15px;
    margin-right:15px
}

.paddingButton{
    padding-bottom: 15px;
}

.btnPrimary{
    background-color: #fff !important;
    border: 1.5px solid $clearBlue !important;
    color: $clearBlue !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19,41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnPrimary:hover{
    background-color: $clearBlue !important;
    border: 1.5px solid $darkBlue !important;
    color:#fff !important;
}

.btnPrimary:disabled{
    background-color: $clearGray;
    border: 1.5px solid $clearGray;
    color:#fff;
    opacity: 1;
}

.btnSecondary{
    background-color:$darkBlue  !important;
    border: 1.5px solid $darkBlue !important;
    color: #fff !important;
    min-width: 110px;
    font-weight: 700;
    font-size: 15px;
    line-height: 19,41px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.btnSecondary:hover{
    background-color: $darkBlue !important;
    border: 1.5px solid $darkBlue !important;
    color:#fff !important;
}

.btnSecondary:disabled{
    background-color: #fff !important;
    border: 1.5px solid $clearGray !important;
    color:$clearGray !important;
    opacity: 1;
}

.ItemCheckbox{
    display: flex;
    margin-top: 24px;
    //flex-direction: column-reverse;
}
.registerInputs{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
}
.registerInputsBlue{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
    color: #005DBF;
    font-weight: 700;
}
.registerInputsGris{
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    width: 100%;
    padding: 0 .5rem;
    height: 27px;
    color:#58595B;
}

.selectRegister{
    height: 27px;
    width: 100%;
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    flex: 1;
}
.selectRegisterBlue{
    height: 27px;
    width: 100%;
    border: 1.5px solid #005DBF;
    border-radius: 5px;
    flex: 1;
    color: #005DBF;
    font-weight: 700;
}

.greyRow{
    display:flex;
    flex-direction: row;
    background-color: #ddd;
    border-radius: .5rem;
    margin-top:1rem;
    padding: .5rem;
    padding: .5rem 2rem;
}

.textAreaBig{
    height: 70px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
}

.textArea{
    height: 32px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
}

.textArea_new{
    height: 27px;
    border: 1.5px solid $darkBlue;
    border-radius: 6px;
}



.bottomRow{
    margin: 20px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
    vertical-align: middle;
    padding: 0 .5rem;
}

.bottomRow> Button{
    margin-left: 12px;
}

.tableButton{
    cursor: pointer;
    color: $tableText;
    text-decoration: none;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 12px;
    line-height: 16px;
}

.tableButton:hover{
    color: $darkBlue;
    padding-top: 0px;
    transition-duration: 0.2s;
}

.tableSlash{
    color: $tableText;
    font-size: 12px;
    line-height: 16px;
}

.traerReq{
    display: flex;
    min-width: 140px;
    height: 30px;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
    color: #697387;
    cursor: pointer;

    border-radius: 10px;
    margin: 0 0 0 auto;
}

.proArrowWrapper {
    position: absolute;
    right: 15px;
    top: 40%;
    transform: translateY(-50%);
}
.proArrow {
    display: inline-block;
    border-style: solid;
    border-color: $clearBlue;
    border-width: 0 2px 2px 0;
    padding: 2.5px;
    vertical-align: middle;
    transition: transform 0.3s;
    transform: rotate(45deg);
}

.proArrow2 {
    display: inline-block;
    border-style: solid;
    border-color: $clearBlue;
    border-width: 0 2px 2px 0;
    padding: 2.5px;
    vertical-align: middle;
    transition: transform 0.3s;
    transform: rotate(-135deg);
}

.traerReq:hover{
    background-color: #F5F7FA;
}

.traerReq > p {
    flex: 1;
    margin: 8px 6px 4px 6px;
}

.traerReq:hover> p{
    color: #005DBF;
}

.traerReq:hover> img{
    filter: brightness(0) saturate(100%) invert(20%) sepia(94%) saturate(1860%) hue-rotate(196deg) brightness(107%) contrast(105%);
}


.pseudoInput{
    padding: 1px;
    position: relative;
    cursor: pointer;
}

.pseudoInput>p{
    margin:0;
    color:#CECECE;
    font-size: 16px;
    line-height: 24px;
    height: 22px;
    padding-left: 8px;
    overflow: hidden;
}
.size{
    width: 32px;
    height: 32px;
}
.sizeNew{
    width: 25px;
    height: 25px;
}
.sizeNew2{
    width: 16px;
    height: 16px;
}


.popupReqActive::before{
    width: 12px;
    height: 12px;
    cursor:default;
}

.pseudoInput>img{
    position: absolute;
    top:2px;
    right:2px;
    width: 15px;
    height: 22px;
    margin: 0;
}

.urgente{
    color: #697387;
    line-height: 16px;
    font-size: 12px;
    display: inline;
}
.newArticle{
    margin: 0 5px;
    border: 1.5px solid $orange;
    border-radius: 5px;
    padding: 0 15px;
}

.adjustIcon{
    cursor: pointer;
    width: 20px;
    margin: 0;
}
.pointer{
    cursor: pointer;
}
.pointerNew{
    cursor: pointer;
    margin-top: -4px;
}


//added for article insert ts-147
.orangeIcon{
    fill: $orange;
    filter: saturate(3000%) hue-rotate(190deg);
}

.orangeBorder{
    border: 1.5px solid $orange;
    border-radius: 5px;
}

.orangeText{
    color: $orange;
    font-weight: Bold;
    font-size: 12px;
    line-height: 16px;
}

.orangeCheck{
    background-color: $orange;
    border-color: $orange;
}

//@import '../../index.scss';
@import "../../styleConstants.scss";
@import url("https://fonts.googleapis.com/css?family=Mukta:700");

.container > div > div {
    //padding: 30px 0; //50px
    border-radius: 15px;
    position: relative;
}

.divHeaderModal {
    width: 100%;
    text-align: center;
}

.maintitle {
    font-family: $font-Secular;
    color: $clearBlue;
    font-weight: Bold;
    font-size: 26px;
    line-height: 34px;
    padding: 0 0 0 0;
    margin: 0 0 9px 0;
}

.subtitle {
    font-family: $font-Secular;
    color: $darkBlue;
    font-weight: Bold;
    font-size: 15px;
    line-height: 19px;
    padding: 0 0 0 0;
    margin: 0 0 9px 0;
}

.closeIconModal {
    position: absolute;
    top: 25px;
    right: 25px;
}

.closeIconModal:hover {
    cursor: pointer;
}

.rightArrowIcon {
    position: absolute;
    top: 34px;
    right: 16px;
    cursor: pointer;
}
.leftArrowIcon {
    position: absolute;
    top: 34px;
    left: 16px;
    cursor: pointer;
}

.body {
    flex: 1;
    padding: 6px 2.125rem;
}

.input {
    height: 26px;
    font-size: 14px;
    line-height: 23px;
}

.labelInput {
    font-size: 1rem;
    margin-bottom: 0;
}

//unused buttonYes and buttonNo. replaced by btnPrimary and btnSecondary in tableStyles
.buttonYes {
    background-color: $clearBlue;
    border: 1.5px solid $clearBlue;
    color: #fff;
    padding-top: 6px;
    padding-bottom: 6px;
}

.buttonYes:disabled {
    border-color: #cecece;
    background-color: #cecece;
    opacity: 1;
    color: #fff;
}

.buttonYes:hover {
    background-color: $darkBlue;
    border: 1.5px solid $darkBlue;
    color: #fff;
}

.buttonYes:disabled {
    border-color: #cecece;
    background-color: #cecece;
    opacity: 1;
    color: #fff;
}

.buttonNo {
    background-color: #fff;
    border: 1.5px solid $clearBlue;
    color: $clearBlue;
    padding-top: 6px;
    padding-bottom: 6px;
}

.buttonNo:hover {
    background-color: $clearBlue;
    border: 1.5px solid $clearBlue;
    color: #fff;
}
//end button section

.ml16 {
    margin-left: 16px;
}

.mr16 {
    margin-right: 16px;
}

.footer {
    display: flex;
    flex-direction: row-reverse;
    padding: 11px 0;
}

.extraButtons {
    margin: 0;
    flex: 1;
    display: flex;
}
